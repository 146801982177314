import { Injectable } from '@angular/core';
import {
  ModalController,
  ModalOptions,
  Platform,
} from '@ionic/angular/standalone';

@Injectable({ providedIn: 'root' })
export class ModalService extends ModalController {
  constructor(private platform: Platform) {
    super();
  }

  createCSModal(
    component: any,
    componentProps: Record<string, any>,
    options: {
      common?: Omit<ModalOptions, 'component' | 'componentProps'>;
      mobile?: Omit<ModalOptions, 'component' | 'componentProps'>;
      desktop?: Omit<ModalOptions, 'component' | 'componentProps'>;
    } = {
      common: {},
      mobile: {},
      desktop: {},
    }
  ) {
    let modalOptions: Partial<ModalOptions> = {};
    if (this.platform.is('desktop')) {
      modalOptions = {
        cssClass: 'modal-desktop',
        handle: false,
        ...options.common,
        ...options.desktop,
      };
    } else {
      modalOptions = {
        initialBreakpoint: 1,
        breakpoints: [0, 1],
        handle: true,
        // handleBehavior: 'cycle',
        cssClass: 'modal-mobile',
        ...options.common,
        ...options.mobile,
      };
    }
    // console.log({
    //   component,
    //   componentProps,
    //   ...modalOptions,
    // });
    return this.create({
      component,
      componentProps,
      ...modalOptions,
    });
  }
}
